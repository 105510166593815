import request from '@/utils/request'

/**
 * 获取活动分类
 *
    参数名	必选	类型	说明
    参数是 aid
 * @param {*} data
 */

export function getCateList(data) {
    return request({
        url: '/v2/activity/active/listCats',
        method: 'post',
        params: data
    })
}

/**
 * 获取活动列表
 *
 * @param {*} data
 */

export function getActivityList(data) {
    return request({
        url: '/v2/activity/active/pagelist',
        method: 'post',
        params: data
    })
}

// 获取活动详情

// 参数：

// 参数名	必选	类型	说明
// id	是	int	活动ID
// user_id	否	int	用户ID
export function getActivityDetail(data) {
    return request({
        url: '/v2/activity/active/detail',
        method: 'post',
        params: data
    })
}

// 参数名	必选	类型	说明
// id	是	int	活动ID
// user_id	否	int	用户ID
export function getListRound(data) {
    return request({
        url: '/v2/activity/active/listRound',
        method: 'post',
        params: data
    })
}

// 预约活动
// 参数：
// 参数名	必选	类型	说明
// access_token	是	string	token值
// active_id	是	int	活动ID
// status	是	int	用户预约类型：4线下报名，5线上预约
// reader_id	否	string	读者证号
// active_round_id	否	int	活动场次ID，默认0
// reserve_number	否	int	预约人次
// signup_info	否	string	报名信息
// companion_info	否	string	随行人员信息
export function signUp(data) {
    return request({
        url: '/v2/activity/active/active_user/signup',
        method: 'post',
        params: data
    })
}

// 取消预约活动
// 参数：

// 参数名	必选	类型	说明
// access_token	是	string	token值
// id	是	int	活动报名ID
export function unsignup(data) {
    return request({
        url: '/v2/activity/active/active_user/cancelSignup',
        method: 'post',
        params: data
    })
}

// 候补活动
// 参数：

// 参数名	必选	类型	说明
// access_token	是	string	token值
// active_id	是	int	活动ID
// reader_id	否	string	读者证号
// active_round_id	否	int	活动场次ID，默认0
// signup_info	否	string	报名信息
export function candidate(data) {
    return request({
        url: '/v2/activity/active_user/candidate',
        method: 'post',
        params: data
    })
}

// 取消候补活动
// 参数：

// 参数名	必选	类型	说明
// access_token	是	string	token值
// id	是	int	活动报名ID
export function uncandidate(data) {
    return request({
        url: '/v2/activity/active_user/cancelCandidate',
        method: 'post',
        params: data
    })
}

// 用户申请再办一次活动
// 参数：

// 参数名	必选	类型	说明
// access_token	是	string	token值
// active_id	是	int	活动ID
export function wantjoin(data) {
    return request({
        url: '/v2/activity/active_user/userWantJoin',
        method: 'post',
        params: data
    })
}

// 用户候补排名
// 参数：

// 参数名	必选	类型	说明
// access_token	是	string	token值
// active_id	是	int	活动ID
// active_round_id	否	int	活动场次ID，默认0

export function rank(data) {
    return request({
        url: '/v2/activity/active_user/candidateRank',
        method: 'post',
        params: data
    })
}

// 获取活动场次列表
// 参数：

// 参数名	必选	类型	说明
// active_id	是	int	活动ID
export function roundlist(data) {
    return request({
        url: '/v2/activity/active/listRound',
        method: 'post',
        params: data
    })
}
