<template>
  <div class="activity-container">
    <div class="activity-content">
      <div class="tab-secondNav" v-if="secondNav.length > 0">
        <ul class="tab-secondNav-choose-item">
          <li
            v-for="(item, i) in secondNav"
            :key="i"
            :class="{ tabActivesecondNav: cur2 == item.id }"
            @click="changeSecondNav(item, i)"
          >
            {{ item.name }}
            <span :class="{ tabActivesecondNavSpan: cur2 == item.id }">
              <span :class="{ tabActivesecondNavSubSpan: cur2 == item.id }">
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="activity-switch-type" v-if="categoryList.length>0">
        <div class="activity-switch-type-title"></div>
        <div class="activity-switch-type-content">
          <div
            class="activity-switch-type-content-li"
            :class="{
              'activity-switch-type-content-li-active': cur == item.id,
              imgType: item.label_name.length > 4,
            }"
            v-for="(item, index) in categoryList"
            :key="item.id"
            @click="handleChangeValue(item.id)"
          >
            {{ item.label_name }}
          </div>
        </div>
      </div>
      <div class="activity-switch-type">
        <div class="activity-switch-type-title"></div>
        <div class="activity-switch-type-content">
          <div
            class="activity-switch-type-content-li"
            :class="{
              'activity-switch-type-content-li-active': cur3 == item.id,
              imgType: item.name.length > 4,
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleChangeStatus(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 最新·最热 -->
      <div class="activity-content-hot-new">
        <div class="activity-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="activity-content-hot-new-title"
          @click="handleChangeValue2('active_start_time')"
        >
          <div
            :style="{
              'font-weight': value2 == 'active_start_time' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="activity-content-hot-new-title-bg"
            :style="{
              display: value2 == 'active_start_time' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="activity-content-new-mid-hot"></div>
        <div
          class="activity-content-hot-new-title activity-content-hot-new-title1"
          @click="handleChangeValue2('view_count')"
        >
          <div :style="{ 'font-weight': value2 == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="activity-content-hot-new-title-bg"
            :style="{ display: value2 == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="activity-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div>

      <div v-if="total > 0">
        <div>
          <ul class="activity-ul">
            <li
              class="activity-li"
              v-for="(item, index) in content"
              :key="'activity' + index"
              @click="toNewPage(item)"
            >
              <!-- <div class="listState" :class="`current${item.status}`"> -->
                <!-- <div class="listStateFont" v-if="item.status == 1">已结束</div>
                <div class="listStateFont" v-else-if="item.status == 2">
                  进行中
                </div>
                <div
                  class="listStateFont"
                  v-else-if="
                    item.status == 3 || item.status == 4 || item.status == 5
                  "
                >
                  待开始
                </div> -->
                <!-- 1活动已结束，2活动进行中，3报名已结束活动未开始，4预约(报名)中，5报名未开始 -->
                <!-- <div class="listStateFont" v-if="item.status == 1">已结束</div>
                <div class="listStateFont" v-else-if="item.status == 2">进行中</div>
                <div class="listStateFont" v-else-if="item.status == 3">待开始</div>
                <div class="listStateFont" v-else-if="item.status == 4">报名中</div>
                <div class="listStateFont" v-else-if="item.status == 5">待报名</div> -->
              <!-- </div> -->
                <div class="activity-li-banner">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.banner"
                    alt=""
                  />
                  <div class="activelistState" :class="`activecurrent${item.status}`">
                <!-- 1活动已结束，2活动进行中，3报名已结束活动未开始，4预约(报名)中，5报名未开始 -->
                <div class="activelistStateFont" v-if="item.status == 1">{{item.status_text}}</div>
                <div class="activelistStateFont" v-else-if="item.status == 2">{{item.status_text}}</div>
                <div class="activelistStateFont" v-else-if="item.status == 3">{{item.status_text}}</div>
                <div class="activelistStateFont" v-else-if="item.status == 4">{{item.status_text}}</div>
                <div class="activelistStateFont" v-else-if="item.status == 5">{{item.status_text}}</div>
              </div>
                </div>
                <div class="activity-li-bottom">
                  <div class="activity-li-bottom-title">
                    {{ item.title }}
                  </div>
                  
                  <div class="activity-li-bottom-des">
                    <img
                      class="activity-li-bottom-icon"
                      src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                      alt=""
                    />
                    <div class="activity-li-bottom-icon-title">
                      {{ item.active_time }}
                    </div>
                  </div>
                  <div class="activity-li-bottom-des">
                    <img
                      class="activity-li-bottom-icon"
                      src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_address_icon.png"
                      alt=""
                    />
                    <div class="activity-li-bottom-icon-title">
                      {{ item.address }}
                    </div>
                  </div>
                </div>
              <img
                class="activity-li-selected"
                src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
                alt=""
              />
            </li>
          </ul>
        </div>
        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getActivityList"
        />
      </div>
      <!-- 没有内容显示的页面 -->
      <Nocontent
        title="暂无群文活动，请持续关注哦～"
        v-if="total == 0"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { getCateList, getActivityList } from "@/api/activity/activity";
import Pagination from "@/components/Pagination";
import Nocontent from "@/views/components/Nocontent";
import { navChildList } from "../../api/header/header";
export default {
  data() {
    return {
      listLoading: true,
      total: 0,
      page: 1,
      limit: 9,
      aid: process.env.VUE_APP_AID,
      cur: 0,
      cur2: 0,
      cur3: -1,
      id: this.$route.query.id,
      //活动状态，-1全部，1已结束，2报名中，3报名已结束活动未开始，4进行中，5报名未开始
      // typeList: [
      //   { id: -1, name: "全部" },
      //   { id: 6, name: "待开始" },
      //   { id: 2, name: "进行中" },
      //   { id: 1, name: "已结束" },
      // ],
      typeList: [
        { id: -1, name: "全部" },
        { id: 5, name: "待报名" },
        { id: 4, name: "报名中" },
        { id: 3, name: "待开始" },
        { id: 2, name: "进行中" },
        { id: 1, name: "已结束" },
      ],
      subTypeList: ["默认", "最新", "最热"],

      content: [],
      categoryList: [],
      option2: [
        { value: "active_start_time", label: "最新上线" },
        { value: "view_count", label: "人气最高" },
      ],
      value1: "全部",
      value2: "active_start_time",
      secondNav: [],
      column_id: "",
    };
  },
  components: {
    Pagination,
    Nocontent,
  },
  watch: {
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.cur3 = -1;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getActivityList();
      this.getChildNav();
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.column_id = this.$route.query.column_id;
    this.cid = this.$route.query.cid;
    if(this.cid){
        this.cur2 = this.cid;
    }
    this.getActivityList();
    this.getChildNav();
  },
  methods: {
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };

      navChildList(params).then((res) => {
        console.log(res);
        // this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data;
          //   console.log("secondNav", this.secondNav);
        }
      });
    },
    handleChangeStatus(id) {
      this.page = 1;
      this.cur3 = id;

      this.getActivityList();
    },
    handleChangeValue(id) {
      this.page = 1;
      this.cur = id;
      this.getActivityList();
    },

    handleChangeValue2(value2) {
      this.page = 1;
      this.value2 = value2;
      this.getActivityList();
    },

    getActivityList() {
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        status: this.cur3,
        column_id: this.column_id,
      };

      if (this.value2 != "") {
        params.sort_field = this.value2;
      }
      console.info(this.value2);
      if (parseInt(this.cur) > 0) {
        params.label_id = parseInt(this.cur);
      }
      console.log(params);
      getActivityList(params).then((res) => {
        // this.listLoading = true;
        if (res.code == 0) {
          this.content = res.data.datalist;
          console.log("content", this.content);
          this.total = res.data.total;
          this.categoryList = res.data.label_list;
          if (res.data.label_list.length > 0) {
            this.categoryList.unshift({
              id: 0,
              label_name: "全部",
            });
          }
        }
      });
      this.listLoading = false;
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `/active-default/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/active_default_index.scss";
// .tab-secondNav-choose-item {
//   font-size: 0.17rem;
//   border-bottom: 1px solid #d5d5d5;
//   padding-bottom: 0.14rem;
// }
// .tab-secondNav-choose-item li {
//   cursor: pointer;
//   // margin-left: 0.46rem;
// }
// .tab-secondNav-choose-item li:not(:first-child) {
//   cursor: pointer;
//   margin-left: 0.46rem;
// }
// .tabActivesecondNav {
//   color: #761f1e;
//   position: relative;
// }

// .tabActivesecondNavSpan {
//   width: 100%;
//   height: 3px;
//   display: inline-block;
//   position: absolute;
//   bottom: -0.14rem;
//   left: 0rem;
// }
// .tabActivesecondNavSubSpan {
//   width: 80%;
//   background: #991c10;
//   height: 3px;
//   display: inline-block;
//   position: absolute;
//   animation: 0px;
//   margin: auto;
//   left: 0px;
//   right: 0px;
// }
// .tab-subSwitch {
//   margin-top: 0.33rem;
// }
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item {
  display: flex;
}
.tab-secondNav-choose-item li {
  margin-left: 10px;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
</style>
